import React, { useState, useEffect, useContext } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import AuthContext from "../../context/auth/authContext";
import AssignRoleModal from "./assignRole";
import UnassignRoleModal from "./unAssign";
import Pagination from "../../components/Layout/Pagination";

const RolePermissionsModal = ({ roleId, show, handleClose }) => {
    const authContext = useContext(AuthContext);
    const { listRolePermissions, role_permissions = [] } = authContext; // Ensure roles defaults to an empty array

    // State for pagination

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of permissions per page

    useEffect(() => {
        if (roleId) {
            listRolePermissions(roleId); // Fetch permissions for the specified role
            setCurrentPage(1); // Reset to the first page when role changes
        }
    }, [roleId]);

    // Calculate paginated permissions
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentPermissions = role_permissions.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Role Permissions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Permission Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPermissions.length > 0 ? (
                            currentPermissions.map((permission, index) => (
                                <tr key={index}>
                                    <td>{permission}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="1" className="text-center">
                                    No permissions found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={role_permissions.length}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={Math.ceil(role_permissions.length / itemsPerPage)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>

            <AssignRoleModal />
            <UnassignRoleModal />
        </Modal>
    );
};

export default RolePermissionsModal;
