import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import CustomersContext from "../../context/otogas/customers/customersContext";
import StationsContext from "../../context/otogas/stations/stationsContext";

const AddCustomerVehicle = ({ show, handleClose, customerId }) => {
    const navigate = useNavigate();
    const { addVehicle, products,
        updateVehicle, selected_vehicle, customers, getCustomers, notification, clear_notification, listProducts } = useContext(CustomersContext);
    const { listStations, stations } = useContext(StationsContext);

    const { register, handleSubmit, watch, setValue, formState: { errors, isValid } } = useForm({
        mode: "onChange",
        defaultValues: {
            customerCode: customerId || "",
            vehicleRegistrationNumber: "",
            vehicleMake: "",
            phoneNumber: "",
            phoneNumber2: "",
            vehicleModel: "",
            tankCapacity: "",
            productCode: "",
            conversionStation: "",
            conversionDate: "",
            status: "0"
        }
    });

    useEffect(() => {
        getCustomers();
        listStations();
        listProducts();
    }, []);

    useEffect(() => {
        if (customerId) {
            setValue("customerCode", customerId);
        }
    }, [customerId, setValue]);

    useEffect(() => {
        if (notification) {
            setAlertMessage({ text: notification.text, type: notification.type });
            setShowAlert(true);
        }
    }, [notification]);

    const stationsOptions = stations.map((station) => ({
        value: station.stationCode,
        label: station.stationName
    }));

    const productCodeOptions = products.map((product) => ({
        value: product.productCode,
        label: product.productName
    }));

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

    const handleConfirm = () => {
        setShowAlert(false);
        navigate('/otogas/vehicles');
        clear_notification();
        handleClose();
    };

    const validateTankCapacity = (value) => {
        if (!value) return "Tank Capacity is required";
        if (isNaN(value) || value <= 0) return "Tank Capacity must be a positive number";
        return true;
    };

    const customerOptions = customers.map((customer) => ({
        value: customer.customerCode,
        label: `${customer.customerName} - ${customer.customerPhone}`
    }));

    const onSubmit = (data) => {
        if (selected_vehicle) {
            updateVehicle(data);
        } else {
            addVehicle(data);
        }
        handleClose(); // Close modal after submit
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Add Vehicle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="form-group">
                        <label>Vehicle Registration Number</label>
                        <input
                            type="text"
                            {...register("vehicleRegistrationNumber", { required: "Vehicle Registration Number is required" })}
                            className={`form-control ${errors.vehicleRegistrationNumber ? "is-invalid" : ""}`}
                        />
                        {errors.vehicleRegistrationNumber && <div className="text-danger mt-1">{errors.vehicleRegistrationNumber.message}</div>}
                    </div>

                    <div className="form-group">
                        <label>Vehicle Make</label>
                        <input
                            type="text"
                            {...register("vehicleMake", { required: "Vehicle Make is required" })}
                            className={`form-control ${errors.vehicleMake ? "is-invalid" : ""}`}
                        />
                        {errors.vehicleMake && <div className="text-danger mt-1">{errors.vehicleMake.message}</div>}
                    </div>

                    <div className="form-group">
                        <label>Vehicle Model</label>
                        <input
                            type="text"
                            {...register("vehicleModel", { required: "Vehicle Model is required" })}
                            className={`form-control ${errors.vehicleModel ? "is-invalid" : ""}`}
                        />
                        {errors.vehicleModel && <div className="text-danger mt-1">{errors.vehicleModel.message}</div>}
                    </div>

                    <div className="form-group">
                        <label> Phone Number</label>
                        <input
                            type="text"
                            {...register("phoneNumber", {
                                required:
                                    "Phone Number is required",
                            })}
                            className={`form-control ${errors.phoneNumber
                                ? "is-invalid"
                                : ""
                                }`}
                        />
                        {errors.phoneNumber && (
                            <div className="text-danger mt-1">
                                {errors.phoneNumber.message}
                            </div>
                        )}
                    </div>

                    <div className="form-group">
                                                <label>Phone Number 2</label>
                                                <input
                                                    type="text"
                                                    {...register("phoneNumber2", {
                                                        validate: (value) =>
                                                            value !== watch("phoneNumber") || "Phone Number 2 cannot match Phone Number",
                                                    })}
                                                    className={`form-control ${errors.phoneNumber2 ? "is-invalid" : ""}`}
                                                />
                                                {errors.phoneNumber2 && (
                                                    <div className="text-danger mt-1">{errors.phoneNumber2.message}</div>
                                                )}
                                            </div>

                    <div className="form-group">
                        <label>Tank Capacity</label>
                        <input
                            type="number"
                            {...register("tankCapacity", { validate: validateTankCapacity })}
                            className={`form-control ${errors.tankCapacity ? "is-invalid" : ""}`}
                        />
                        {errors.tankCapacity && <div className="text-danger mt-1">{errors.tankCapacity.message}</div>}
                    </div>

                    <div className="form-group">
                        <label>Product Code</label>
                        <select
                            {...register("productCode", { required: "Product Code is required" })}
                            className={`form-control ${errors.productCode ? "is-invalid" : ""}`}
                        >
                            <option value="">Select Product Code</option>
                            {productCodeOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        {errors.productCode && <div className="text-danger mt-1">{errors.productCode.message}</div>}
                    </div>

                    <div className="form-group">
                        <label>Conversion Station</label>
                        <select
                            {...register("conversionStation", { required: "Conversion Station is required" })}
                            className={`form-control ${errors.conversionStation ? "is-invalid" : ""}`}
                        >
                            <option value="">Select Conversion Station</option>
                            {stationsOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        {errors.conversionStation && <div className="text-danger mt-1">{errors.conversionStation.message}</div>}
                    </div>

                    <div className="form-group">
                        <label>Conversion Date</label>
                        <input
                            type="date"
                            {...register("conversionDate", { required: "Conversion Date is required" })}
                            className={`form-control ${errors.conversionDate ? "is-invalid" : ""}`}
                        />
                        {errors.conversionDate && <div className="text-danger mt-1">{errors.conversionDate.message}</div>}
                    </div>

                    <div className="form-group">
                        <label>Status</label>
                        <select
                            {...register("status", { required: "Status is required" })}
                            className={`form-control ${errors.status ? "is-invalid" : ""}`}
                        >
                            <option value="0">Inactive</option>
                            <option value="1">Active</option>
                        </select>
                        {errors.status && <div className="text-danger mt-1">{errors.status.message}</div>}
                    </div>

                    <div className="form-group mt-4">
                        <Button type="submit" variant="primary" disabled={!isValid}>
                            Save
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default AddCustomerVehicle;
