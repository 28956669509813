import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form, Modal } from 'react-bootstrap';
import CustomersContext from '../../context/otogas/customers/customersContext';
import StationsContext from '../../context/otogas/stations/stationsContext';
import Select from "react-select";

const TransactionFormModal = ({ show, handleClose }) => {
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const customerContext = useContext(CustomersContext);
    const stationsContext = useContext(StationsContext);

    const { tills, listTills } = stationsContext;
    const { addMpesaTransaction } = customerContext;
    const [selectedTill, setSelectedTill] = useState(null);

    useEffect(() => {
        if (show) {
            listTills(); // Fetch tills when the modal opens
        }
    }, [show]);

    const onSubmit = (data) => {
        // Prepare data object as per required structure
        const transactionData = {
            transID: data.transID,
            amount: data.amount,
            businessShortCode: selectedTill?.value || data.businessShortCode, // Get value from Select or input field
            phoneNumber: data.phoneNumber
        };

        console.log(transactionData); // For debugging
        addMpesaTransaction(transactionData); // Pass data to the context function
        reset();  // Reset form fields after submission
        handleClose();  // Close modal after form submission
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Transaction Form</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        {/* Transaction ID */}
                        <Form.Group controlId="transID">
                            <Form.Label>Transaction ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Transaction ID"
                                {...register('transID', { required: true })}
                                isInvalid={!!errors.transID}
                            />
                            <Form.Control.Feedback type="invalid">
                                Transaction ID is required.
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Amount */}
                        <Form.Group controlId="amount">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter Amount"
                                {...register('amount', { required: true, min: 1 })}
                                isInvalid={!!errors.amount}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.amount?.type === 'required' ? 'Amount is required.' : 'Amount must be greater than 0.'}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Till */}
                        <Form.Group controlId="businessShortCode">
                            <Form.Label>Till (Business Short Code)</Form.Label>
                            <Select
                                options={tills?.map(till => ({
                                    value: till.storeNumber,
                                    label: `${till.tillNumber} - ${till.tillName}`
                                }))}
                                value={selectedTill}
                                onChange={(selectedOption) => {
                                    setSelectedTill(selectedOption);
                                    setValue('businessShortCode', selectedOption?.value, { shouldValidate: true }); // Manually set till value
                                }}
                            />
                            {errors.businessShortCode && <p className="text-danger">Till (Business Short Code) is required.</p>}
                        </Form.Group>

                        {/* Phone Number */}
                        <Form.Group controlId="phoneNumber">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Phone Number"
                                {...register('phoneNumber', {
                                    required: true,
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message: 'Phone number must be 10 digits.'
                                    }
                                })}
                                isInvalid={!!errors.phoneNumber}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.phoneNumber?.message || 'Phone number is required.'}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Submit Button */}
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TransactionFormModal;
