import React, { useState, useEffect, useContext } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import AuthContext from "../../context/auth/authContext";
import Pagination from "../../components/Layout/Pagination"

const AssignedUsersModal = ({ roleId, show, handleClose }) => {
    const authContext = useContext(AuthContext);
    const { listUsersByRole, role_users = [] } = authContext; // Ensure role_users defaults to an empty array

    // State for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of users per page

    console.log(role_users);
    console.log(roleId, 'role id');

    useEffect(() => {
        if (roleId) {
            listUsersByRole(roleId); // Fetch users assigned to the role
            setCurrentPage(1); // Reset to the first page when role changes
        }
    }, [roleId]);

    // Calculate paginated users
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentUsers = role_users.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Users Assigned to Role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>User Code</th>
                            <th>Payroll Number</th>
                            <th>Phone Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentUsers.length > 0 ? (
                            currentUsers.map((user, index) => (
                                <tr key={index}>
                                    <td>{user.name}</td>
                                    <td>{user.userCode}</td>
                                    <td>{user.payrollNumber}</td>
                                    <td>{user.phoneNumber}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="text-center">
                                    No users assigned to this role
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={role_users.length}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={Math.ceil(role_users.length / itemsPerPage)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AssignedUsersModal;
