import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import AuthContext from '../../context/auth/authContext';
import UserContext from '../../context/users/userContext';
import Select from 'react-select';

const AssignRoleModal = ({ show, handleClose, role }) => {
    const { assignRole } = useContext(AuthContext);
    const { users, fetchUsers } = useContext(UserContext);

    console.log(role)




    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        fetchUsers();
    }
        , []);

    const handleAssign = () => {
        if (selectedUser) {
            assignRole( selectedUser.value, role.roleCode);
            handleClose();
        }
    };




    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Assign Role: {role?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Select User</Form.Label>
                        <Select
                            options={users.map((user) => ({
                                value: user.userCode,
                                label: `${user.firstName} ${user.lastName}`
                            }))}
                            onChange={setSelectedUser}
                            placeholder="Choose a user..."
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleAssign} disabled={!selectedUser}>
                    Assign Role
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AssignRoleModal;
