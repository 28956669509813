import React, { useState, useContext, useEffect } from 'react';
import { Dropdown, Table, Form, Button, Row, Col, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/Layout/Pagination';
import Select from 'react-select';

import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Main from '../../components/Layout/Main';
import RemoveSale from './removeSale';
import TransferSale from './transferSale';
import CustomersContext from '../../context/otogas/customers/customersContext';
import StationsContext from '../../context/otogas/stations/stationsContext';
import SweetAlertWrapper from '../../components/Layout/sweetAlert';
import AuthContext from '../../context/auth/authContext';
import DownloadButton from '../../components/util/downloader';
import { download_sales_per_day, download_monthly_sales } from '../../api/otogas';
import Spinner from '../../components/Layout/spinner';
import { formatDate as formatteDate } from '../../api/util';


import userCan from "../../config/user_can";

const ListSales = () => {
    const customersContext = useContext(CustomersContext);
    const {
        sales,
        salesTotalRecords,
        listSales,
        payment_types,
        listPaymentTypes,
        notification,
        clear_notification,
    } = customersContext;
    const stationsContext = useContext(StationsContext);
    const { stationDispensers, listStationDispensers, current_station_dispensers, current_dispenser_nozzles, listDispenserNozzles, stations, listStations } = stationsContext;
    const authContext = useContext(AuthContext);
    const { user } = authContext;
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15); // Number of items per page
    const [filters, setFilters] = useState({
        shiftNumber: "",
        stationCode: "",
        dispenserName: "",
        nozzleName: "",
        paymentTypeName: "",
        startDate: "",
        endDate: "",
        orderByColumn: "",
        isDescending: true,

    });

    const [loading, setLoading] = useState(false); // Add loading state
    const [showDateRangeModal, setShowDateRangeModal] = useState(false);
    const [showRemoveSaleModal, setShowRemoveSaleModal] = useState(false);
    const [showTransferSaleModal, setShowTransferSaleModal] = useState(false);
    const [selectedSale, setSelectedSale] = useState(null); // Store entire sale object
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });

    useEffect(() => {
        if (notification) {
            setAlertMessage({
                text: notification.text,
                type: notification.type,
            });
            setShowAlert(true);
        }
    }, [notification]);

    useEffect(() => {
        listStations()
    }, []);

    useEffect(() => {
        setLoading(true); // Set loading to true before the API call
        listSales(filters.shiftNumber, filters.stationCode, filters.dispenserName, filters.nozzleName, filters.paymentTypeName, filters.startDate, filters.endDate, currentPage, itemsPerPage, filters.orderByColumn,
            filters.isDescending)
            .finally(() => setLoading(false)); // Set loading to false after the API call completes
        listPaymentTypes();
    }, [currentPage, filters, itemsPerPage]);

    const handleFilterChange = (selectedOption, actionMeta) => {
        setFilters({
            ...filters,
            [actionMeta.name]: selectedOption ? selectedOption.value.trim() : '',
        });
    };

    // const handleSort = (column) => {
    //     setFilters((prevFilters) => ({
    //         ...prevFilters,
    //         orderByColumn: String(column).toUpperCase(), // Convert to uppercase using toUpperCase()
    //         isDescending: prevFilters.orderByColumn === column ? !prevFilters.isDescending : false,
    //     }));
    // };


    const handleSort = (column) => {
        // Convert column to PascalCase format (first letter uppercase, rest as-is)
        const toPascalCase = (str) => {
            return str
                .replace(/(^\w|[A-Z]|\b\w)/g, match => match.toUpperCase()) // Capitalize each word
                .replace(/[\s_]+/g, ''); // Remove any whitespace or underscores
        };

        const formattedColumn = toPascalCase(column);

        setFilters((prevFilters) => ({
            ...prevFilters,
            orderByColumn: formattedColumn, // Use the formatted column
            isDescending: prevFilters.orderByColumn === formattedColumn ? !prevFilters.isDescending : false,
        }));
    };



    const handleDateRangeChange = (dates) => {
        const [start, end] = dates;

        const formatDate = (date) => {
            return date ? date.toISOString().split('T')[0] : null;
        };

        // Update the filters with both dates even if end is not selected
        setFilters({
            ...filters,
            startDate: formatDate(start),
            endDate: formatDate(end),
        });
    };

    // Helper function to convert string to Date object
    const parseDate = (dateStr) => {
        return dateStr ? new Date(dateStr) : null;
    };

    const handleApplyFilters = () => {
        setCurrentPage(1); // Reset to first page
        setLoading(true); // Set loading to true when applying filters
        listSales(filters.shiftNumber, filters.stationCode, filters.dispenserName, filters.nozzleName, filters.paymentTypeName, filters.startDate, filters.endDate, 1, itemsPerPage)
            .finally(() => setLoading(false)); // Set loading to false after API call
    };

    const handleRemoveSale = (sale) => {
        setSelectedSale(sale);
        setShowRemoveSaleModal(true);
    };

    const handleTransferSale = (sale) => {
        setSelectedSale(sale); // Store the entire sale object
        setShowTransferSaleModal(true);
    };

    const handleCloseModals = () => {
        setShowRemoveSaleModal(false);
        setShowTransferSaleModal(false);
        setSelectedSale(null);
    };

    useEffect(() => {
        if (filters.stationCode && filters.stationCode !== "") {
            listStationDispensers(filters.stationCode);
        }
    }, [filters.stationCode]);

    useEffect(() => {
        if (filters.dispenserName && filters.dispenserName !== "") {
            const activeDispenserCode = current_station_dispensers.find(d => d.dispenserName === filters.dispenserName).dispenserCode;
            listDispenserNozzles(activeDispenserCode);
        }
    }, [filters.dispenserName]);

    const totalPages = Math.ceil(salesTotalRecords / itemsPerPage);

    const stationOptions = [{ value: '', label: 'All' }, ...stations.map(station => ({ value: station.stationCode, label: station.stationName }))];
    const dispenserOptions = [{ value: '', label: 'All' }, ...current_station_dispensers.map(dispenser => ({ value: dispenser.dispenserName, label: dispenser.dispenserName }))];
    const nozzleOptions = [{ value: '', label: 'All' }, ...current_dispenser_nozzles.map(nozzle => ({ value: nozzle.nozzleName, label: nozzle.nozzleName }))];

    return (
        <>
            <Header />
            <Sidebar />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={() => {
                        setShowAlert(false);
                        clear_notification();
                    }}
                    confirmBtnText="OK"
                    type={alertMessage.type || "info"}
                />
                <h2>List of Sales</h2>

                <Form>
                    <Row className="mb-3 align-items-center">
                        <Col md={2}>
                            <Form.Control
                                type="text"
                                placeholder="Shift Number"
                                name="shiftNumber"
                                value={filters.shiftNumber}
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        shiftNumber: e.target.value,
                                    })
                                }
                            />
                        </Col>
                        <Col md={2}>
                            <Select
                                placeholder="Select Station"
                                name="stationCode"
                                options={stationOptions}
                                onChange={handleFilterChange}
                            />
                        </Col>
                        <Col md={2}>
                            <Select
                                placeholder="Select Dispenser"
                                name="dispenserName"
                                options={dispenserOptions}
                                onChange={handleFilterChange}
                                isDisabled={!filters.stationCode}
                            />
                        </Col>
                        <Col md={2}>
                            <Select
                                placeholder="Select Nozzle"
                                name="nozzleName"
                                options={nozzleOptions}
                                onChange={handleFilterChange}
                                isDisabled={!filters.dispenserName}
                            />
                        </Col>
                        <Col md={2}>
                            <Form.Control
                                as="select"
                                name="paymentTypeName"
                                value={filters.paymentTypeName}
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        paymentTypeName: e.target.value,
                                    })
                                }
                            >
                                <option value="">All Payment Types</option>
                                {(payment_types || []).map((type) => (
                                    <option
                                        key={type.paymentTypeId}
                                        value={type.paymentTypeName}
                                    >
                                        {type.paymentTypeName}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                        <Col md={2}>
                            <DatePicker
                                selected={parseDate(filters.startDate)} // Starting point of the range
                                onChange={handleDateRangeChange} // Handles both start and end
                                startDate={parseDate(filters.startDate)} // Start of range
                                endDate={parseDate(filters.endDate)} // End of range
                                selectsRange
                                className="form-control"
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Select Date Range"
                                isClearable
                                popperPlacement="bottom" // Ensures the calendar shows below the input
                                shouldCloseOnSelect={false} // Ensures both dates can be selected
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3 align-items-center">
                        <Col md={2}>
                            <DownloadButton
                                existingFunction={download_sales_per_day}
                                reportType="daily"
                            />
                        </Col>
                        <Col md={2}>
                            <DownloadButton
                                className="btn btn-secondary"
                                existingFunction={download_monthly_sales}
                                reportType="monthly"
                            />
                        </Col>
                    </Row>
                </Form>

                {/* Spinner */}
                {loading ? (
                    <Spinner />
                ) : (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th onClick={() => handleSort('shiftNumber')} style={{ cursor: 'pointer' }}>
                                    Shift Number {filters.orderByColumn === 'ShiftNumber' && (filters.isDescending ? '▼' : '▲')}
                                </th>
                                <th>Station</th>
                                <th onClick={() => handleSort('quantity')} style={{ cursor: 'pointer' }}>
                                    Quantity {filters.orderByColumn === 'Quantity' && (filters.isDescending ? '▼' : '▲')}
                                </th>
                                <th>Vehicle Registration</th>
                                <th>Dispenser</th>
                                <th>Nozzle</th>
                                <th onClick={() => handleSort('amount')} style={{ cursor: 'pointer' }}>
                                    Amount {filters.orderByColumn === 'Amount' && (filters.isDescending ? '▼' : '▲')}
                                </th>

                                <th>Payment Type</th>
                                <th>Sale Id</th>
                                <th onClick={() => handleSort('dateCreated')} style={{ cursor: 'pointer' }}>
                                    Date {filters.orderByColumn === 'DateCreated' && (filters.isDescending ? '▼' : '▲')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sales.length === 0 ? (
                                <tr>
                                    <td colSpan="12" className="text-center">
                                        No sales available at the moment
                                    </td>
                                </tr>
                            ) : (
                                sales.map((sale, index) => (
                                    <tr key={sale.id}>
                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                        <td>{sale.shiftNumber}</td>
                                        <td>{sale.stationName}</td>
                                        <td>{sale.quantity}</td>
                                        <td>{sale.vehicleRegistrationNumber}</td>
                                        <td>{sale.dispenserName}</td>
                                        <td>{sale.nozzleName}</td>
                                        <td>{sale.amount}</td>
                                        <td>{sale.paymentTypeName}</td>
                                        <td>{sale.saleId}</td>
                                        <td>{formatteDate(sale.dateCreated)}
                                        </td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                    Actions
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={() => handleTransferSale(sale)}
                                                        disabled={!userCan(user, 'POST:Sales:TransferSaleToAnotherNozzle')}
                                                    >
                                                        Transfer Sale
                                                    </Dropdown.Item>

                                                    <Dropdown.Item
                                                        onClick={() => handleRemoveSale(sale)}
                                                        disabled={!userCan(user, 'POST:Sales:ReverseSaleAsync')}
                                                    >
                                                        Reverse Sale
                                                    </Dropdown.Item>

                                                    <Dropdown.Item
                                                        onClick={() => navigate(`/otogas/payments/${sale.saleId}`)}
                                                    >
                                                        View Payments
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                )}

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    totalItems={salesTotalRecords}
                />
            </Main>

            {/* Remove Sale Modal */}
            <RemoveSale
                show={showRemoveSaleModal}
                handleClose={handleCloseModals}
                saleId={selectedSale?.saleId}
            />

            {/* Transfer Sale Modal */}
            <TransferSale
                show={showTransferSaleModal}
                handleClose={handleCloseModals}
                sale={selectedSale} // Pass the entire sale object
            />
        </>
    );
};

export default ListSales;
